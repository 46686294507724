.container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .map {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }